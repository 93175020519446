<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>{{ application_status.title }} application status state</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  <router-link to="/visa-application-status"> \ Visa Application status</router-link>
                  \ State
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                      v-if="checkIsAccessible('application-status-state', 'create')"
                      @click="createState()"
                      class="mt-1 btn btn-primary"
                  >
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add Visa Application Status
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <!-- table -->
            <div class="table-responsive">

              <v-skeleton-loader
                  v-if="loading"
                  type="table-thead"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>

              <table class="table">
                <thead>
                  <th>Title</th>
                  <th>Change Visa Status</th>
                  <th>For User</th>
                  <th>Is first</th>
                  <th>Is final</th>
                  <th>Action</th>
                </thead>
                <template v-if="statuses && statuses.length>0">
                  <draggable @change="sort" @end="drag=false" @start="drag=true" class="w-100"
                             tag="tbody" v-model="statuses">
                    <tr :key="index" v-for="(status, index) of statuses">
                      <td>
                        <i class="fa fa-sort"></i>
                        <span class="badge text-uppercase"
                              :style="{'background-color':status.color} ">{{ status.title }}</span>
                      </td>
                      <td>
                        {{status.change_visa_status_text || "N/A"}}
                      </td>
                      <td>
                        <i :class="status.is_user?'fa-check text-success':'fa-ban text-danger'"
                           class="fas"></i>
                      </td>
                      <td>
                        <i :class="status.is_first?'fa-check text-success':'fa-ban text-danger'"
                           class="fas"></i>
                      </td>
                      <td>
                        <i :class="status.is_final?'fa-check text-success':'fa-ban text-danger'"
                           class="fas"></i>
                      </td>
                      <td>
                        <a @click="editState(status)"> <i
                            class="mx-3 text-primary fas fa-edit"></i></a>
                        <a @click="deleteState(status.id)"> <i
                            class="text-danger fas fa-trash"></i></a>
                      </td>
                    </tr>
                  </draggable>
                </template>

                <tbody v-else>
                  <tr>
                    <td class="text-center" colspan="50">No data found</td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>
      <create-and-update ref="create-and-update" @refresh="getApplicationStatusState"></create-and-update>

    </div>
  </v-app>
</template>

<script>
import draggable from "vuedraggable";
import VisaApplicationStatusStateService from "@/services/visa/application-status/VisaApplicationStatusStateService";
import VisaApplicationStatusService from "@/services/visa/application-status/VisaApplicationStatusService";
import CreateAndUpdate from "./CreateAndUpdate";

const statusStateService = new VisaApplicationStatusStateService();
const visaApplicationStatus = new VisaApplicationStatusService();

export default {
  name: "Index",
  components: {
    CreateAndUpdate,
    draggable
  },
  data() {
    return {
      statuses: [],
      application_status_id: null,
      application_status: {},
      loading: false,
      drag: false,
    }
  },

  mounted() {
    this.application_status_id = this.$route.params.id;
    this.getAllVisaApplicationStatuses();
    this.getApplicationStatusState();
  },

  methods: {
    getAllVisaApplicationStatuses() {
      visaApplicationStatus.show(this.application_status_id).then(response => {
        this.application_status = response.data.visaApplicationStatus;
      }).catch(() => {});
    },

    createState() {
      this.$refs["create-and-update"].createVisaApplicationStatus();
    },

    editState(status) {
      this.$refs["create-and-update"].editVisaApplicationStatus(status);
    },

    getApplicationStatusState() {
      this.loading = true;
      statusStateService.paginate({application_status_id: this.application_status_id}).then(response => {
        this.statuses = response.data.data;
        this.loading = false
      }).catch(error => {
        this.loading = false
        this.statuses = [];
      })
    },

    sort() {
      this.isSorting = true;
      statusStateService
          .sort(this.statuses)
          .then(response => {
            this.isSorting = false;
            this.$snotify.success('Visa Application Status Sorted !!');
            this.getApplicationStatusState();
          })
          .catch(err => {
            this.isSorting = false;
            // console.log(err)
            this.$snotify.error("Oops something went wrong");
          });
    },
    deleteState(id) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.loading = true
            statusStateService.delete(id).then(response => {
              this.$snotify.success("Application status state deleted successfully");
              this.getApplicationStatusState();
            }).catch(error => {
              this.$snotify.error("Something went wrong. Please try again later")
            }).finally(this.loading = false)
          }
        }
      });
    }
  }
}
</script>