<template>
  <v-dialog
      v-model="dialog"
      max-width="600"
      scrollable
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title class="text-h5">
          <span>{{ title }} application status state</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field :error="$v.status.title.$error" dense label="Title" outlined
                            v-model="status.title"></v-text-field>
              <span class="text-danger" v-if="$v.status.title.$error">Title is required</span>
            </v-col>

            <v-col cols="12">
              <v-select
                  label="Change Visa Status"
                  v-model="status.change_visa_status"
                  :items="visaStatuses"
                  item-text="name"
                  item-value="value"
                  clearable
                  outlined
                  dense
              ></v-select>
            </v-col>

            <v-col cols="4">
              <ColorPicker :width="100" :height="100" :disabled="false" v-model="status.color"
              ></ColorPicker>
              <span class="text-danger" v-if="$v.status.color.$error">Title is required</span>
            </v-col>

            <v-col cols="4">
              <v-switch @change="status.is_final == true?status.is_final = false:null" dense label="Is first" outline
                        v-model="status.is_first"></v-switch>
            </v-col>

            <v-col cols="4">
              <v-switch @change="status.is_first == true?status.is_first = false:null" dense label="Is final" outline
                        v-model="status.is_final"></v-switch>
            </v-col>

            <v-col cols="4">
              <v-switch dense label="Is User" outline
                        v-model="status.is_user"></v-switch>
            </v-col>

            <v-col cols="12">
              <label>Remarks</label>
              <v-textarea  v-model="status.remarks" outlined dense></v-textarea>
            </v-col>

          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="mr-2" text dark medium
            @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn
            v-if="checkIsAccessible('application-status-state', 'create') || checkIsAccessible('application-status-state', 'edit')"
            class="btn btn-primary"
            medium
            @click="createOrUpdate()"
            :loading="loading"
        >
          {{ edit ? "Update" : "Save" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import ColorPicker from 'vue-color-picker-wheel';
import VisaApplicationStatusStateService from "@/services/visa/application-status/VisaApplicationStatusStateService";

const statusStateService = new VisaApplicationStatusStateService();

export default {
  name: "CreateAndUpdate",
  components: {
    ColorPicker
  },
  data() {
    return {
      title: '',
      edit: false,
      dialog: false,
      loading: false,
      visaStatuses: [],
      application_status: {},
      status: {
        title: '',
        change_visa_status: '',
        remarks: '',
        color: '',
        is_user: 0
      },
      color_statuses: [
        {title: "Red", value: 'red'},
        {title: "Green", value: 'green'},
        {title: "Orange", value: 'orange'},
        {title: "Yellow", value: 'yellow'},
        {title: "Red", value: 'red'},
      ]
    }
  },
  validations: {
    status: {
      title: {required},
      color: {required}
    }
  },
  methods: {

    onColorChange(color) {
      this.status.color = color
    },

    closeDialog() {
      this.dialog = false;
      this.resetForm();
    },

    createVisaApplicationStatus() {
      this.title = 'Add';
      this.dialog = true;
      this.edit = false;
      this.resetForm();
      this.status.application_status_id = this.$route.params.id;
      this.getAllChangeVisaStatus();
    },

    editVisaApplicationStatus(status) {
      this.title = "Edit"
      this.dialog = true;
      this.edit = true;
      this.resetForm();
      this.status = status;
      this.getAllChangeVisaStatus();
    },

    getAllChangeVisaStatus() {
      statusStateService.getAllChangeVisaStatus(this.status.application_status_id).then(response => {
        this.visaStatuses = response.data.visa_status
      }).catch(() => {
        this.visaStatuses = [];
      });
    },

    createOrUpdate() {
      this.$v.$touch();
      if (this.$v.status.$error) {
        setTimeout(() => {
          this.$v.status.$reset();
        }, 3000)
      } else {
        if (this.status.id){
          this.update();
        }else{
          this.create();
        }
      }
    },

    create() {
      this.loading = true
      statusStateService.create(this.status).then(response => {
        this.$snotify.success("Status create successfully")
        this.$emit("refresh");
        this.closeDialog();
        this.loading = false;

      }).catch(error => {
        this.$snotify.error("Something went wrong. Please try again later")
        this.loading = false
      })
    },

    update() {
      this.loading = true
      statusStateService.update(this.status.id, this.status).then(response => {
        this.$snotify.success("Status updated successfully")
        this.$emit("refresh");
        this.closeDialog();
        this.loading = false;
      }).catch(error => {
        this.$snotify.error("Something went wrong. Please try again later")
        this.loading = false;
      })
    },

    resetForm() {
      this.$v.$reset();
      this.status = {
        title: '',
        change_visa_status: '',
        remarks: '',
        color: '',
        is_user: 0
      }
    },
  }
}
</script>

<style>
@import 'https://emoji-css.afeld.me/emoji.css';
</style>
